<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-21 09:57:44
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-10 20:24:02
 * @FilePath: /ww-swisstyle/src/views/detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="detail-container">
    <div class="content">
      <div class="img-wrapper">
        <img :src="detail.mainImg" alt="">   
      </div>
    
      <div class="title-section warp">
        <h3>{{ detail.title[$i18n.locale] }}</h3> 
        <p v-for="(item, index) in detail.desc" :key="index">{{ item[$i18n.locale] }}</p>
      </div>

      <div class="img-gallery">
        <div class="img-wrapper" v-for="(item, index) in productItemList" :key="index">
          <img :src="item.imageUrl" alt="">
        </div>
        <!-- <div class="img-wrapper">
          <img src="@/assets/img3.jpg" alt="">
        </div>
        <div class="img-wrapper">
          <img src="@/assets/img4.jpg" alt="">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

    export default {
        name:'Detail',
        data() {
            return { 
              productItemList: [],
                 detail: {
                    mainImg: '',
                    img: [ ],
                    // title: {
                    //   zh: '',
                    //   en: '',
                    // },
                    // desc: [
                    //   {
                    //     zh: '',
                    //     en: '',
                    //   }
                    // ]
                    title: {
                      zh: '',
                      en: ''
                    },
                    desc: [
                     
                       
                    ]
                 }
            }
        },
        created() {
          this.fetchProductItemList()
          this.fetchProductDetail()
        },
        methods: {
          async fetchProductItemList() {
            const data = await api.getProductItemList({productId: this.$route.query.id});
            data.rows.forEach(item => {
              item.imageUrl = process.env.VUE_APP_OSS_URL + item.imageUrl
            })
            console.log(data)
            this.detail.mainImg = data.rows[0].imageUrl
            // 1-3张图
            
            this.productItemList = data.rows.splice(1, 3)
          },

          async fetchProductDetail() {
            const res = await api.getProductDetail({productId: this.$route.query.id});
            console.log(res.data, '3333')
            this.detail.title.zh = res.data.productCnName
            this.detail.title.en = res.data.productEnName
            if (res.data.productCnDesc1) {
              this.detail.desc.push({
                zh: res.data.productCnDesc1,
                en: res.data.productEnDesc1
              })
            }
            if (res.data.productCnDesc2) {
              this.detail.desc.push({
                zh: res.data.productCnDesc2,
                en: res.data.productEnDesc2
              })
            }
            if (res.data.productCnDesc3) {
              this.detail.desc.push({
                zh: res.data.productCnDesc3,
                en: res.data.productEnDesc3
              })
            }
            if (res.data.productCnDesc4) {
              this.detail.desc.push({
                zh: res.data.productCnDesc4,
                en: res.data.productEnDesc4
              })
            }
            if (res.data.productCnDesc5) {
              this.detail.desc.push({
                zh: res.data.productCnDesc5,
                en: res.data.productEnDesc5
              })
            }

            if (res.data.productCnDesc6) {
              this.detail.desc.push({
                zh: res.data.productCnDesc6,
                en: res.data.productEnDesc6
              })
            }

            
          },

          
          
        }
    }
</script>

<style lang="less" scoped>
.detail-container {
  width: 100%;
  min-height: 100vh;
  background: #fff;
//   padding-top: 80px; // 为固定导航栏留出空间

  .content {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .warp {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 0 20px; // 添加两侧内边距
  }

  .img-wrapper {
    width: 100%;
    margin: 0 auto;
    
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .title-section {
    padding: 50px 10px;
    
    h3 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #333;
      line-height: 1.4;
    }

    p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 15px;
      color: #333;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .img-gallery {
    width: 100%;

    .img-wrapper {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// 响应式适配
@media (max-width: 1280px) {
  .detail-container {
    .warp {
      width: 100%;
      padding: 50px 15px;
    }
  }
}

@media (max-width: 768px) {
  .detail-container {
    padding-top: 60px;

    .title-section {
      padding: 30px 20px;
      
      h3 {
        font-size: 22px;
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    .img-gallery {
      .img-wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .detail-container {
    .title-section {
      padding: 20px 20px;
      
      h3 {
        font-size: 20px;
      }

      p {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    .img-gallery {
      .img-wrapper {
        margin-bottom: 15px;
      }
    }
  }
}
</style>